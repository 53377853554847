import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import { groupInput, helperMixin, twoStateBindValue } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: { BaseCheckbox, DropdownItem },
    mixins: [helperMixin, twoStateBindValue, groupInput],
    emits: ["add-item", "edit", "delete"],
    props: {
        withActionTool: {
            type: Boolean,
            default: true,
            required: false
        },
        withAddItem: {
            type: Boolean,
            default: true
        },
        withEdit: {
            type: Boolean,
            default: true
        },
        withDelete: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            displayTool: false
        };
    }
});
