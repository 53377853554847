import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import selection from "@/http/selection";
import selectionStore from "@/store/selection";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: { BaseModal, BaseFormApp, BaseFormGroup },
    mixins: [
        helperMixin,
        baseModal,
        saveAndUpdate(selection()),
    ],
    props: {
        type: {
            type: String,
            required: false
        },
        parentId: {
            default: null,
        },
        selectionId: {
            default: null,
        },
    },
    computed: {
        selectionStore,
        selectionParent() {
            return this.selectionStore.findById(this.form.parent_id, this.selectionStore.state[this.type]);
        },
        updateId() {
            return this.selectionId;
        },
        redirectAfterSubmit() {
            return false;
        },
    },
    methods: {
        afterSubmit(data) {
            let parent = [];
            if (!data.parent_id) {
                parent = this.selectionStore.state[this.type];
            }
            else if (this.selectionParent) {
                parent = this.selectionParent.recursive_childs =
                    this.selectionParent.recursive_childs || [];
            }
            if (this.isUpdateState) {
                const i = parent.findIndex((d) => d.id == data.id);
                if (i !== -1) {
                    parent[i] = data;
                }
            }
            else {
                parent.unshift(data);
            }
            this.$emit("update:modelValue", false);
        },
        setForm() {
            var _a, _b;
            this.type ? (this.form.type = this.type) : "";
            this.form.parent_id = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.parent_id) || this.parentId;
            this.form.name = (_b = this.data) === null || _b === void 0 ? void 0 : _b.name;
        },
    },
});
